import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Bowser from 'bowser';
import camelcaseKeys from 'camelcase-keys';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import queryString from 'query-string';
import snakecaseKeys from 'snakecase-keys';
import { Box, Grid, Typography } from '@material-ui/core';

import client, { getUrl } from '$landing-page/client';
import Button from '$landing-page/components/button';
import Loading from '$landing-page/components/loading';
import PageSection from '$landing-page/components/page-section';
import PageWrapper from '$landing-page/components/page-wrapper';
import StoreButton from '$landing-page/components/store-button';
import { ERROR_CODES, HTTP_CALL_STATUS } from '$landing-page/constants';

import useStyles from './styles';

let browserData = {};
// Check for window for Gatsby SSR build
if (typeof window !== `undefined`) {
    browserData = Bowser.getParser(window.navigator.userAgent);
}

export const pageQuery = graphql`
    query studyPageQuery {
        site {
            siteMetadata {
                productEthicaHealth {
                    appStoreLink
                    googlePlayLink
                }
            }
        }
    }
`;

const StudyPage = (props) => {
    const {
        location,
        studyId,
        data: {
            site: {
                siteMetadata: {
                    productEthicaHealth: { appStoreLink, googlePlayLink },
                },
            },
        },
    } = props;

    const { pathname, search } = location;
    const title = `Join study`;
    const description = `Joining Ethica Data study`;
    const query = queryString.parse(search);

    const [studyData, setStudyData] = useState(null);
    const [getStudyDataStatus, setGetStudyDataStatus] = useState(HTTP_CALL_STATUS.UNKNOWN);
    const [getStudyDataErrorCode, setGetStudyDataErrorCode] = useState();

    const classes = useStyles();

    const isAndroidDownload = useMemo(() => Object.prototype.hasOwnProperty.call(query, 'showDownloadAndroid'), [query]);

    const getStudyData = useCallback(async () => {
        setGetStudyDataStatus(HTTP_CALL_STATUS.LOADING);
        setGetStudyDataErrorCode(undefined);
        try {
            const response = await client.api.get('/study/info/basic/', {
                params: snakecaseKeys({
                    studyId,
                }),
            });
            setStudyData(camelcaseKeys(response.data, { deep: true }));
            setGetStudyDataStatus(HTTP_CALL_STATUS.SUCCEEDED);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            if (error.response.status === 400) {
                setGetStudyDataErrorCode(error.response.data.error_code);
            }
            setGetStudyDataStatus(HTTP_CALL_STATUS.FAILED);
        }
    }, [studyId]);

    const recordStudyLandingPageVisit = useCallback(async () => {
        try {
            await client.api.post(
                '/study/user/record_study_landing_page_visit/',
                snakecaseKeys({
                    studyId,
                })
            );
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }, [studyId]);

    const handleParticipate = useCallback(() => {
        if (browserData.is('ios') && appStoreLink) {
            window.location.href = appStoreLink;
        } else if (browserData.is('android') && googlePlayLink) {
            // TODO: This part needs more investigation if we want to rely on env variables.
            window.location.href = `intent://ethicadata.com/study/${studyId}#Intent;package=com.ethica.logger;component=com.ethica.logger/.ui.StartActivity;scheme=https;S.browser_fallback_url=${getUrl(
                `/study/${studyId}?showDownloadAndroid`
            )};end`;
        } else {
            window.location.href = getUrl(`/app/public/join-study?studyId=${studyId}`);
        }
    }, [appStoreLink, googlePlayLink, studyId]);

    const handlePlayStoreClick = useCallback(() => {
        window.location.href = `${googlePlayLink}&referrer=study_id%3D${studyId}`;
    }, [googlePlayLink, studyId]);

    useEffect(() => {
        getStudyData();
        if (browserData.is('ios')) {
            recordStudyLandingPageVisit();
        }
    }, [getStudyData, recordStudyLandingPageVisit]);

    return (
        <PageWrapper pageMetaData={{ title, description, pathname }} showFooter={false}>
            <PageSection className={classes.root}>
                <Loading
                    loading={getStudyDataStatus === HTTP_CALL_STATUS.LOADING}
                    error={getStudyDataStatus === HTTP_CALL_STATUS.FAILED}
                    errorText={
                        getStudyDataErrorCode === ERROR_CODES.PARAM_INVALID
                            ? 'The study you are looking for was not found.'
                            : 'Something happened during study data loading.'
                    }
                    errorButtonText="Reload"
                    onErrorButtonClick={getStudyData}
                />
                {getStudyDataStatus === HTTP_CALL_STATUS.SUCCEEDED && (
                    <Box className={classes.studyWrapper} style={{ backgroundImage: `url(${getUrl(studyData.appBg)})` }}>
                        <Grid container direction="column" className={classes.studyDataWrapper} wrap="nowrap">
                            <Grid item container alignItems="center" className={classes.studyHeaderWrapper}>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h4">{studyData.name}</Typography>
                                    <Typography variant="h5">{studyData.orgName}</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} className={classes.headerButtonWrapper}>
                                    {!isAndroidDownload && (
                                        <Button variant="contained" color="primary" onClick={handleParticipate}>
                                            Participate
                                        </Button>
                                    )}
                                    {isAndroidDownload && (
                                        <StoreButton
                                            store="google-play"
                                            size="large"
                                            color="primary"
                                            onClick={handlePlayStoreClick}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item container xs alignContent="flex-start" className={classes.studyDescriptionWrapper}>
                                {!isAndroidDownload && (
                                    <article
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{ __html: studyData.desc }}
                                        className={classes.studyDescription}
                                    />
                                )}
                                {isAndroidDownload && (
                                    <>
                                        <Typography variant="h5">Thanks for your interest to join the study.</Typography>
                                        <Typography variant="body1">
                                            In order to join, you need to first install Ethica Health app. Clicking on
                                            &quot;Google Play Store&quot; logo above will take you to Google Play Store to
                                            download and install the app.
                                        </Typography>
                                        <Typography variant="body2">
                                            After the download, we will help you to create an account and join.
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </PageSection>
        </PageWrapper>
    );
};

StudyPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                productEthicaHealth: PropTypes.shape({
                    appStoreLink: PropTypes.string.isRequired,
                    googlePlayLink: PropTypes.string.isRequired,
                }),
            }),
        }),
    }).isRequired,
    studyId: PropTypes.string.isRequired,
};

export default StudyPage;
