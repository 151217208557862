import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        margin: theme.spacing(4, 0),
    },
    studyWrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    studyDataWrapper: {
        backgroundColor: 'rgba(256,256,256,0.8)',
        boxSizing: 'border-box',
        marginLeft: '15%',
        width: '70%',
        maxWidth: '70%',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: '3%',
            width: '94%',
            maxWidth: '94%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 30,
            width: 'calc(100% - 60px)',
            maxWidth: 'calc(100% - 60px)',
        },
        height: 'calc(100% - 32px)',
        maxHeight: 'calc(100% - 32px)',
    },
    studyHeaderWrapper: {
        padding: theme.spacing(2, 4),
        background: '#fff',
        boxSizing: 'border-box',
    },
    headerButtonWrapper: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 0, 1, 0),
            textAlign: 'center',
        },
    },
    studyDescriptionWrapper: {
        boxSizing: 'border-box',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: theme.spacing(2, 4),
    },
    studyDescription: {
        margin: theme.spacing(2, 0),
    },
}));
