import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1.5, 3.5),
        textAlign: 'center',
        boxShadow: 'none',
    },
    endIcon: {
        marginLeft: theme.spacing(2),
    },
}));
